import {useEffect, useState} from 'react';
import {useRouteMatch} from 'react-router-dom';
import {useUserConfig} from './hooks';
import {setupSdk, trackPageView} from './helpers/analytics';

const CountlyWrapper = () => {
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);
  const userConfig = useUserConfig();
  const {url} = useRouteMatch();

  useEffect(() => {
    if (!analyticsInitialized) {
      return;
    }

    trackPageView(url, userConfig?.company?.uuid, userConfig?.company?.name);
  }, [url, analyticsInitialized, userConfig]);

  useEffect(() => {
    if (analyticsInitialized) {
      return;
    }
    console.info('Setup countly SDK now for flow.');
    setupSdk(userConfig);
    trackPageView(url, userConfig?.company?.uuid, userConfig?.company?.name);
    setAnalyticsInitialized(true);
  }, [url, userConfig, analyticsInitialized]);
  return null;
};

export default CountlyWrapper;
