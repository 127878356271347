import React from 'react';
import axios from 'axios';
import axiosBetterStacktrace from 'axios-better-stacktrace';
import {Provider} from 'react-redux';
import {ConnectedIntlProvider} from '@ecosio/locales';
import {ErrorBoundary} from '@ecosio/components';
import {
  axiosParamsSerializer,
  configureAxios,
  errorHandler,
  setupAxiosDefaults,
  setupAxiosInterceptors as setupAxios,
  getXSRFCookieNameForEnvironment,
} from '@ecosio/auth';
import {QueryClientProvider} from '@tanstack/react-query';
import createQueryClient from '../queryClient';
import store, {history} from './store';
import AppRouter, {allowedPaths} from './AppRouter';
import {BASE_URL} from './constants';
import {trackError} from './helpers/analytics';

const axiosErrorHandlerWithErrorTracking = (store, allowedPaths) => {
  return (error) => {
    trackError(error);
    return errorHandler(store, allowedPaths)(error);
  };
};
// axios breaks stack traces https://github.com/axios/axios/issues/2387
// this is fixed in 1.0.0-alpha ... https://github.com/axios/axios/releases/tag/v1.0.0-alpha.1
// should be removed once 1.0.0 is out
// https://github.com/axios/axios/pull/4624
axiosBetterStacktrace(axios);
setupAxios(store, allowedPaths);
setupAxiosDefaults(axios);

const XSRF_HEADER = 'X-XSRF-TOKEN';
const csrfCookieName = getXSRFCookieNameForEnvironment();

configureAxios((authAxios) => {
  authAxios.defaults.baseURL = BASE_URL;
  authAxios.defaults.xsrfCookieName = csrfCookieName;
});

axios.interceptors.response.use(
  (response) => response,
  axiosErrorHandlerWithErrorTracking(store, allowedPaths)
);

axios.defaults.paramsSerializer = axiosParamsSerializer;
axios.defaults.baseURL = BASE_URL;
axios.defaults.xsrfCookieName = csrfCookieName;
axios.defaults.xsrfHeaderName = XSRF_HEADER;

const queryClient = createQueryClient();

const App = () => (
  <Provider store={store}>
    <ConnectedIntlProvider>
      <QueryClientProvider client={queryClient}>
        <ErrorBoundary exceptionLogger={console.error} axios={axios}>
          <AppRouter history={history} baseUrl={BASE_URL} />
        </ErrorBoundary>
      </QueryClientProvider>
    </ConnectedIntlProvider>
  </Provider>
);

export default App;
